import React, { useEffect, useState } from "react";
import "./styles/App.css";

function App() {
  const [tg, setTg] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://telegram.org/js/telegram-web-app.js";
    script.async = true;
    script.onload = () => {
      if (window.Telegram && window.Telegram.WebApp) {
        const telegram = window.Telegram.WebApp;
        telegram.ready();
        setTg(telegram);

        const userData = telegram.initDataUnsafe?.user || {};
        setUser(userData);
      }
    };
    document.body.appendChild(script);

    // Очистка скрипта при размонтировании компонента
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const sendData = () => {
    if (tg) {
      tg.sendData(JSON.stringify({ action: "button_clicked" }));
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Добро пожаловать{user ? `, ${user.first_name}` : ""}!</h1>
        <button onClick={sendData}>Отправить данные боту</button>
      </header>
    </div>
  );
}

export default App;

