// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
    background-color: var(--tg-bg-color, #f7f9fc);
    color: var(--tg-text-color, #000);
    font-family: Arial, sans-serif;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: var(--tg-button-color, #0088cc);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: var(--tg-button-hover-color, #005f8c);
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,6CAA6C;IAC7C,iCAAiC;IACjC,8BAA8B;IAC9B,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,iDAAiD;IACjD,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,uDAAuD;EACzD","sourcesContent":[".App {\n    text-align: center;\n    background-color: var(--tg-bg-color, #f7f9fc);\n    color: var(--tg-text-color, #000);\n    font-family: Arial, sans-serif;\n    height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  button {\n    padding: 10px 20px;\n    margin-top: 20px;\n    background-color: var(--tg-button-color, #0088cc);\n    color: white;\n    border: none;\n    border-radius: 5px;\n    font-size: 16px;\n    cursor: pointer;\n  }\n  \n  button:hover {\n    background-color: var(--tg-button-hover-color, #005f8c);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
